import { createContext }       from 'react';
import merge                   from 'deepmerge'
import { useFormInfo }         from 'hooks/form';
import { 
  createDefaultValue, 
  toAuxPath, 
  collectDefaultsFromFields, 
  collectDefaultsFromAux,
  mergeDefaults,
  enrichValues,
  collectAdditionalValues }              from 'components/form/utils/field-utils';
import { getPath, pruneEmpty } from 'utils/utils';

export const ValuesContext = createContext();

const ValuesProvider = ({children}) => {
  const formInfo = useFormInfo()

  function createValues(vpath = "", withSaved = true, useDynamicDefault = true) {
    const auxPath        = toAuxPath(formInfo, vpath)
    const staticDefault  = collectDefaultsFromFields(formInfo, vpath)
    const dynamicDefault = collectDefaultsFromAux(getPath(formInfo.form.aux, auxPath, {}))
    const defaultValues  = useDynamicDefault ? mergeDefaults(staticDefault, dynamicDefault) : staticDefault
    const savedValues    = withSaved ? getPath(formInfo.form.saved, vpath, {}) : {}
    const prunedValues   = pruneEmpty(defaultValues)
    const prefillValues  = formInfo.kind === "PROCESS_FILL" && !vpath ? getPath(formInfo.prefill, auxPath, {}) : {}
    const frontendValues = createDefaultValue(formInfo, defaultValues, auxPath)    
    const valueList      = [defaultValues, frontendValues, prunedValues, prefillValues, savedValues]

    // based-ons and such
    const additionalValues = collectAdditionalValues(formInfo.form.values)
    const values = enrichValues(merge.all(valueList, { arrayMerge: overwriteMerge }), additionalValues)

    return values
  }

  const values = createValues()
  console.log("Adding values to form: %o", pruneEmpty(values))

  return (
    <ValuesContext.Provider value={{ values, createValues }}>
      {children}
    </ValuesContext.Provider>
  )
}

const overwriteMerge = (_destinationArray, sourceArray, _options) => sourceArray

export default ValuesProvider;
