import { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { DesktopDateTimePicker, renderMultiSectionDigitalClockTimeView } from '@mui/x-date-pickers';
import parseISO from 'date-fns/parseISO';
import { isEmptyValue, isValidDate } from "utils/utils";
import { validateField } from "components/form/utils/validate-utils";
import { useFieldInfo } from 'hooks/field';
import { useFormInfo } from 'hooks/form';
import InputError from "components/form/fields/InputError";
import { renderDateDay } from 'components/form/fields/date/DayDate';
import Types from 'types/types';
import { useNotifier } from 'hooks/notification';
import { useAutoSubmitSignal } from 'hooks/autosubmit';
import { useConfig } from 'hooks/config';
import { fieldMinWidthStyle } from 'components/form/utils/field-utils';

function toRuntimeValue(value) {
  try {
    if (value instanceof Date)
      return value.toISOString()
  } catch (error) {
    return null
  }
  return null
}

function toMuiValue(value) {
  try {
    return isEmptyValue(value) ? null : parseISO(value)
  } catch (error) {
    return null
  }
}

const InputDateTimeFieldContent = (props) => {
  const { augProps, fieldProps, info } = useFieldInfo()
  const { setFocus, setViewOpen }      = props
  const { t }                          = useTranslation()
  const formInfo                       = useFormInfo()
  const [error, setError]              = useState(null)
  const [input, setInput]              = useState(toMuiValue(fieldProps.value))
  const notifier                       = useNotifier()
  const {signal}                       = useAutoSubmitSignal()
  const {props: {taskRendering}}       = useConfig()

  delete fieldProps.onChange

  function toHelperText(error) {
    if (info.inMultiple)
      return undefined

    if (fieldProps.error)
      return fieldProps.helperText
    else if (error)
      return t("yup.invalid.datetime")
    else
      return " "
  }

  function handleValidate(e, value) {
    const error = validateField("datetime", fieldProps.required, e, value)
    augProps.setError(error)
  }

  function handleChange(value, context) {
    const { validationError } = context

    augProps.setRuntimeError(undefined)
    setError(validationError)
    setInput(value)
    if (isValidDate(value)) {
      const runtimeValue = toRuntimeValue(value)
      augProps.setValue(runtimeValue)
      handleValidate(null, runtimeValue)
    }
	}

  function handleBlur(e) {
    const value = error ? toRuntimeValue(input) : fieldProps.value
    if (error || !Types.isValidDate(input) ) {
      augProps.setValue(null)
      setInput(null)
      notifier.error("invalid date in field " + fieldProps.label)
    }

    setFocus(false)
    fieldProps.onBlur(e)
    handleValidate(e, value)
    signal()
  }

  function handleFocus(e) {
    setFocus(true)
    fieldProps.onFocus(e)
  }

  const slotProps = {
    slotProps: {
      textField: (params) => ({
        ...fieldProps,
        onBlur: handleBlur,
        onFocus: handleFocus,
        ...params,

        id: fieldProps.id,
        error:  params.error || fieldProps.error,
        helperText :  toHelperText(params.error),
        style: taskRendering == 'standard' ? fieldMinWidthStyle(formInfo, info.field) : undefined,

        fullWidth: true,
      }),
      actionBar: {actions: ['today','clear', 'cancel', 'accept']},
    }
  }

  // fix for clearing the field
  useEffect(() => {
    if (input == null) 
      augProps.setValue(null)
  }, [input])

  return (
    <DesktopDateTimePicker
      displayWeekNumber={true}
      desktopModeMediaQuery="@media (not (pointer: coarse))"
      className="grs-InputDateTimeField"
      format="dd-MM-yyyy HH:mm:ss"
      ampm={false}
      ampmInClock={false}
      renderDay={renderDateDay}
      value={input}
      onOpen={() => setViewOpen(true) }
      onClose={() => setViewOpen(false)}

      views={["year", "month","day", "hours", "minutes", "seconds"]}
      viewRenderers={{
        hours: renderMultiSectionDigitalClockTimeView,
        minutes: renderMultiSectionDigitalClockTimeView,
        seconds: renderMultiSectionDigitalClockTimeView,
      }}

      onChange={handleChange}
      {...augProps.inputProps}

      {...slotProps}
    />
  )
}

const InputDateTimeField = (props) => (
  <InputError>
    <InputDateTimeFieldContent />
  </InputError>
)

export default InputDateTimeField
